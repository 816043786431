<template>
  <div style="overflow: hidden; position: relative" :style="backgroundStyle" id="main-body">
    <div class="container-lg p-0" style="max-width: 1020px; background-color: white; overflow: visible">

      <div class="jumbotron jumbotron-fluid" style="min-height: 130px">


        <div class="container">
          <div class="row">
            <div class="col-auto" style="margin-top: 50px">
              <img :src="logo" style="width: 64px">
            </div>

            <div class="col d-md-flex align-items-center d-none"  style="margin-top: 50px;">
              <div id="tagline">
                The Only Device That Tests Everything
              </div>
            </div>

            <div class="col">
              <div class="d-flex flex-column align-items-end">
                <div id="socials" class="row">
                  <ul class="social-icons">
                    <li><a href="https://www.facebook.com/metsim.simulator"><img :src="facebook" alt="Facebook"></a></li>
                    <li><a href="https://www.youtube.com/channel/UCTsMFJdAnw_ULC4eOeIaT4Q"><img :src="youtube" alt="YouTube"></a></li>
                    <li><a href="https://www.linkedin.com/"><img :src="linkedin" alt="LinkedIn"></a></li>
                    <li><a href="https://www.instagram.com/metsimpro"><img :src="instagram" alt="Instagram"></a></li>
                  </ul>
                </div>
                <div id="contact-details" class="row">
                  Contact :
                  <a class="p-0" href="mailto:oe@oealternatives.com">oe@oealternatives.com</a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- Navbar -->
      <nav class="navbar navbar-expand-lg navbar-dark" style="background-color: #4c4c4c;  width: calc(100% + 18px); margin-left:-9px">
        <div class="left-corner"></div>
        <div class="right-corner"></div>
        <div class="container">
          <a class="navbar-brand" href="/">Metsim</a>
          <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav me-auto">
              <li class="nav-item">
                <router-link to="/" class="nav-link" active-class="active">Home</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/Ecus" class="nav-link" active-class="active">Ecu List</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/Catalog" class="nav-link" active-class="active">E-Catalog</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/Contact" class="nav-link" active-class="active">Contact</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/Customer" class="nav-link" active-class="active">Login</router-link>
              </li>
            </ul>
          </div>
        </div>
      </nav>


      <div>
        <router-view />
      </div>


      <!-- Footer -->
      <footer class="footer" id="footer">
        <div class="container">
          <div class="row align-items-stretch h-100">
            <div class="col-auto text-center mb-5">
              <img :src="fullDarkLogo" alt="Logo" class="footer-logo mb-2" style="width: 171px;">
            </div>

<!--            <div class="col-sm text-start mb-5">-->
<!--              <h4>Arge Office</h4>-->

<!--              <div class="row mb-3">-->
<!--                <div class="col-auto">-->
<!--                  <i class="bi bi-geo-alt me-1"></i> <span class="fw-bold">Address:</span>-->
<!--                </div>-->

<!--                <div class="col p-0">-->
<!--                  Gaziantep Üniversitesi TeknoPark C Blok No:120 Gaziantep, Şehitkamil, TÜRKİYE-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="row">-->
<!--                <div class="col-auto">-->
<!--                  <i class="bi bi-envelope me-1"></i> <span class="fw-bold">Email:</span>-->
<!--                </div>-->
<!--                <div class="col p-0">-->
<!--                  <a href="mailto:info@metsimpro.com" class="text-white">info@metsimpro.com</a>-->
<!--                </div>-->
<!--              </div>-->

<!--            </div>-->

            <div class="col-sm text-start align-top">
              <h4>Main Office</h4>

              <div class="row mb-3">
                <div class="col-auto">
                  <i class="bi bi-geo-alt"></i> <span class="fw-bold">Address:</span>
                </div>
                <div class="col p-0">
                  1591 N Main St Ste B, Layton, UT 84041
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-auto">
                  <i class="bi bi-telephone"></i>  <span class="fw-bold">Phone:</span>
                </div>
                <div class="col p-0">
                  <a href="tel:+3852220109" class="text-white">+1 (385) 222-0109</a>
                </div>
              </div>

              <div class="row">
                <div class="col-auto">
                  <i class="bi bi-envelope me-1"></i> <span class="fw-bold">Email:</span>
                </div>
                <div class="col p-0">
                  <a href="mailto:oe@oealternatives.com" class="text-white">oe@oealternatives.com</a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </footer>
      <footer id="footer-bottom">
        <div class="container">

          <div class="row">
            <!-- Copyrights -->
            <div class="col">
              <div class="copyright">
                © Copyright 2022 by <a href="#">Metsim Pro</a>. All Rights Reserved.
              </div>
            </div>

            <!-- Menu -->
            <div class="col">
              <nav id="sub-menu">
                <ul>
                  <li class="border-0"><a href="/Faqs">FAQ's</a></li>
                  <li><a href="/Contact">Contact</a></li>
                  <li><a href="/Ecus">Ecu List</a></li>
                </ul>
              </nav>
            </div>
          </div>

        </div>
        <!-- 960 Container / End -->

      </footer>
    </div>
  </div>

</template>

<script setup>
import logo from '@/assets/logo.png'
import fullDarkLogo from '@/assets/full-dark-logo.png'
import background from '@/assets/noise.png'
import facebook from '@/assets/Social Icons/facebook.png'
import instagram from '@/assets/Social Icons/instagram.png'
import linkedin from '@/assets/Social Icons/linkedin.png'
import youtube from '@/assets/Social Icons/youtube.png'

const backgroundStyle = {
  backgroundImage: `url(${background})`,
  backgroundRepeat: 'repeat'
};

</script>


<style scoped>


.features .card {
  padding: 20px;
}

#tagline {
  color: #888;
  border-left: 1px solid #e8e8e8;
  padding: 5px 0 5px 20px;
  float: left;
  font-size: 12px;
}

#socials {
  margin: 23px 0 13px 0;
  float: right;
}

#contact-details {
  float: right;
  margin: -1px 0 1px 0;
  display: block;
  color: #888;
  font-size: 11px;
}

#contact-details a{
  color: #666;
  text-decoration: none;
}

.social-icons {
  list-style: none; /* Remove bullet points */
  display: flex;    /* Make the list horizontal */
  padding: 0;       /* Remove padding */
  margin: 0;        /* Remove margin */
  gap: 5px;        /* Add space between icons */
}

.social-icons li {
  display: inline-block; /* Make list items inline */
}

.social-icons a img {
  width: 16px;  /* Set the width of the icons */
  height: 16px; /* Set the height of the icons */
  opacity: 0.5;   /* Make icons partially transparent */
  transition: opacity 0.3s ease; /* Smooth transition for hover effect */
}

.social-icons a img:hover {
  opacity: 1;     /* Make icons fully visible on hover */
}


.left-corner {
  border-color: transparent #888 transparent transparent;
  border-width: 0 9px 9px 0;
  float: left;
  left: 0px;
}

.right-corner {
  border-color: #888 transparent transparent transparent;
  border-width: 9px 9px 0 0;
  float: right;
  left: calc(100% - 17px)
}

.left-corner, .right-corner {
  display: block;
  width: 0px;
  height: 0px;
  border-style: solid;
  position: relative;
  top: 32px;
  opacity: 0.58;
  filter: alpha(opacity = 58);
}



#footer {
  color: #ddd;
  background: #4c4c4c;
  padding: 30px 0 31px 0;
  font-size: 12px;
}

#footer h4 {
  padding: 0;
  margin: 0 0 15px 0;
  font-size: 14px;
  color: #fff;
  line-height: 30px;
}

.footer-logo {
  width: 150px; /* Adjust the logo width */
}



.footer a {
  text-decoration: none;
}



#footer-bottom {
  color: #aaa;
  background: #424242;
  width: 100%;
  padding: 15px 0;
  margin: 0 auto;
  font-size: 12px;
}

#sub-menu {
  float: right;
  font-size: 11px;
}

#sub-menu ul {
  list-style: none; /* Remove bullet points */
  display: flex;    /* Make the list horizontal */
  padding: 0;       /* Remove padding */
  margin: 0;        /* Remove margin */
}

#sub-menu ul li {
  border-left: 1px solid #505050;
  padding: 0 8px 0 8px;
}

#sub-menu ul a {
  text-decoration: none;
  color: #aaa;
}

#sub-menu ul a:hover{
  color: #eee !important;
}

.copyright a, .copyright span {
  color: #eee !important;
  text-decoration: none;
}



</style>
