import { createRouter, createWebHistory } from 'vue-router'
// import { authGuard} from '@auth0/auth0-vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../components/HomePage.vue')
    },
    {
        path: '/Ecus',
        name: 'Ecus',
        component: () => import('../components/ECUList.vue')
    },
    {
        path: '/Catalog',
        name: 'Catalog',
        component: () => import('../components/CatalogPage.vue')
    },
    {
        path: '/Contact',
        name: 'Contact',
        component: () => import('../components/HomePage.vue')
    },
    {
        path: '/Customer',
        name: 'Customer',
        component: () => import('../components/LoginPage.vue')
    },
    {
        path: '/Contact',
        name: 'Contact',
        component: () => import('../components/ContactPage.vue')
    },
    {
        path: '/FAQs',
        name: 'FAQs',
        component: () => import('../components/LoginPage.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes
})



export default router;